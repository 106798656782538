@import '../../../mixins.scss';

.AcceptJobRequirementsNotAccepted {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  &__overviewClose {
    margin-top: 12px;
    border-bottom: 1px solid #f2f4f6;
    width: calc(100% - 16px);
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 8px;
    box-sizing: content-box;
    img {
      margin-left: 11px;
    }
  }
  &__title {
    margin-top: 11px;
    margin-bottom: 21px;
    width: percentage($number: 324/375);
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 22.2px;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 33px;
  }
  &__body {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: calc(100% - 32px);
    margin: 0 auto;
  }
  &__requirements {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 19px;
      margin-top: 0;
    }
  }
  &__buttonsContainer {
    margin: auto 0 13px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  &__close {
    @include primaryButton;
    margin-bottom: 12px;
  }
  &__back {
    @include secondaryButton;
  }
}
