@import '../../mixins.scss';

.JobNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 320px;
  margin: 0 auto;
  &__text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 25px;
  }
  &__exploreButton {
    @include primaryButton;
    width: 200px;
  }
}
