@import '../../mixins.scss';

.PayoutPaymaxOptions {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  &__title {
    @include payoutTitle;
  }
  &__hint {
    width: calc(100% - 50px);
    margin: 25px auto 0;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #233b58;
  }
  &__optionsContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    margin: 34px auto;
    button {
      width: 100%;
      border: 1px solid #fff;
      color: #414141;
      height: 70px;
      font-size: 16px;
      border-radius: 5px;
      transition: 0.5s all;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      font-style: normal;
      font-size: 15px;
      line-height: 18px;
      align-items: center;
      font-weight: 400;
      letter-spacing: 0em;
      &:first-child {
        margin-bottom: 15px;
      }
      &.active {
        color: #7767e4;
        transition: 0.5s all;
        font-size: 17px;
        border: 1px solid #7767e4;
      }
    }
  }
  &__footerContainer {
    width: calc(100% - 32px);
    margin: auto auto 0;
  }
  &__errorMessage {
    @include errorMessage;
  }
}
