@import '../../mixins.scss';

.UploadConfirmationModal {
  @include modal;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px;
  p {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }
  &__confirmButton {
    @include primaryButton;
    height: 56px;
    margin-top: 30px;
    box-shadow: none;
    width: 263px;
  }
}
