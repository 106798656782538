.AppLayout {
  display: flex;
  flex: 1;
  flex-direction: column;
  &__center {
    flex: 1;
    display: flex;
  }

  &__main {
    display: flex;
    flex: 1;
    padding: 10px;
    max-width: 800px;
  }
}
#modals {
  position: fixed;
  left: 0;
  top: 0;
  &:not(:empty) {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
}
