@import '../../../../mixins.scss';

.JobRequirements {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__requirementsList {
    display: flex;
    flex-direction: column;
  }
  &__requirementRow {
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f4f6;
    width: calc(100% - 16px);
    margin: 0 auto;
    .JobRequirements__yesAndNo {
      margin-left: auto;
      display: flex;
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        &:last-child {
          margin-left: 18px;
          margin-right: 25px;
        }
      }
    }
    .JobRequirements__requirementName {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin-left: 27px;
    }
    .JobRequirements__checkBoxes {
      margin-left: auto;
      height: 22px;
      .JobRequirements__customCheckbox {
        @include customChecbox;
        margin-left: 18px;
        margin-right: 25px;
      }
    }
  }
  &__title {
    margin-top: 11px;
    margin-bottom: 21px;
    width: percentage($number: 324/375);
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 22.2px;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: 25px;
  }
  &__nextButton {
    @include primaryButton;
    margin: auto auto 18px;
    width: calc(100% - 32px);
    display: block;
  }
  &__errorMessage {
    @include errorMessage;
    margin-left: 10px;
  }
}
