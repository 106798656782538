@import '../../mixins.scss';

.ChoiceQuestion {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .AcceptJob__questionContainer {
    height: 70vh;
    overflow-y: scroll;
  }
  .AcceptJob__question {
    font-size: 19px;
    font-weight: 400;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.68;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    width: calc(100% - 39px - 22px);
    margin-left: 22px;
  }
  .ChoiceQuestion__answersSection {
    margin-top: 20px;
    width: calc(100% - 39px - 22px);
    margin-left: 22px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #2a2a2a;
    .ChoiceQuestion__answer {
      display: flex;
      flex-direction: column;
      margin-bottom: 21px;
      .ChoiceQuestion__choice {
        display: flex;
        align-items: flex-start;

        [type='radio']:checked,
        [type='radio']:not(:checked) {
          position: absolute;
          left: -9999px;
        }
        [type='radio']:checked + label,
        [type='radio']:not(:checked) + label {
          position: relative;
          padding-left: 28px;
          line-height: 20px;
          display: inline-block;
        }
        [type='radio']:checked + label:before,
        [type='radio']:not(:checked) + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 15px;
          height: 15px;
          border: 1px solid #bbb;
          border-radius: 100%;
          background: #fff;
        }
        [type='radio']:checked + label:before {
          border: 2px solid #7767e4;
        }
        [type='radio']:checked + label:after,
        [type='radio']:not(:checked) + label:after {
          content: '';
          width: 7px;
          height: 7px;
          background: #7767e4;
          position: absolute;
          top: 6px;
          left: 6px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        [type='radio']:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        [type='radio']:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        .ChoiceQuestion__answerText {
          margin: 0 0 0 11px;
        }
      }
      .ChoiceQuestion__answerHint {
        margin-top: 10px;
        margin-left: 24px;
        display: flex;
        align-items: flex-start;
        .ChoiceQuestion__answerHintText {
          margin: 0 0 0 12px;
          font-size: 14px;
          color: #2a2a2a;
          line-height: 1.5em;
        }
        .ChoiceQuestion__answerHintIcon {
          width: 18px;
        }
      }
    }
  }
  .ChoiceQuestion__steps {
    margin: auto auto 18px auto;
  }
  .ChoiceQuestion_nextButton {
    @include primaryButton;
    margin: 0 auto 18px;
    width: calc(100% - 32px);
    display: block;
  }
}
