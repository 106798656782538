@import '../../mixins.scss';

.ChoosePaymentMethod {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  .ChoosePaymentMethod__title {
    @include payoutTitle;
  }
  .ChoosePaymentMethod__optionsContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    margin: 54px auto;
    button {
      width: 100%;
      border: 1px solid #fff;
      color: #414141;
      height: 70px;
      font-size: 16px;
      border-radius: 5px;
      transition: 0.5s all;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      align-items: center;
      &:first-child {
        margin-bottom: 15px;
      }
      &.active {
        color: #7767e4;
        transition: 0.5s all;
        font-size: 17px;
        border: 1px solid #7767e4;
      }
    }
  }
  .ChoosePaymentMethod__footerContainer {
    width: calc(100% - 32px);
    margin: auto auto 0;
  }
  .ChoosePaymentMethod__errorMessage {
    @include errorMessage;
  }
}
