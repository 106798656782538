@import '../../../mixins.scss';

.Confirmation {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Confirmation__pageBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
  }
  .Confirmation__title {
    margin-bottom: 29px;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  }
  .Confirmation__checkmark {
    margin-bottom: 73px;
  }
  .Confirmation__subTitle {
    margin-bottom: 27px;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0;
  }
  .Confirmation__list {
    padding-left: 0;
    width: calc(100% - 116px);
    li {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      span {
        color: #7565e0;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 2px solid #7565e0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        margin-right: 9px;
        line-height: 23px;
      }
      p {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        width: calc(100% - 26px);
        margin-top: 0;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .Confirmation__nextButton {
    @include primaryButton;
    margin: auto auto 18px;
    width: calc(100% - 32px);
    display: block;
  }
}
