@import '../../mixins.scss';

.TextInput .error-msg {
  position: absolute;
  margin-top: 2px;
  @include errorMessage;
}

.TextInput img {
  position: absolute;
  width: 17px;
  top: 0;
  margin-top: 24px;
  margin-left: 32px;
  padding-top: 0;
}

.TextInput {
  position: relative;
  margin-top: 10px;
  width: 100%;
  input.TextInput__input {
    font-size: 17px;
    padding: 25px 10px 10px 32px;
    display: block;
    height: 60px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border: none;
  }
  input:focus {
    outline: none;
  }
}

.input-label {
  position: absolute;
  pointer-events: none;
  left: 32px;
  top: 19px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.53px;
  text-align: left;
  color: rgba(39, 39, 39, 0.4);
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

input:focus ~ .input-label,
input:disabled ~ .input-label,
input:valid ~ .input-label {
  top: 12px;
  font-size: 12px;
  color: rgba(39, 39, 39, 0.4);
}
