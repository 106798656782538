.OverviewSteps {
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
  border-radius: 25px;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  padding: 32px 15px;
  .OverviewSteps__steps-connecting-line {
    position: absolute;
    height: calc(100% - 110px);
    width: 1px;
    background-color: #7767e4;
    top: 45%;
    transform: translateY(-50%);
    left: 23px;
  }
  .OverviewSteps__step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 26px;
    &:last-child {
      margin-bottom: 0;
    }
    .OverviewSteps__step-status {
      z-index: 1;
    }
    .OverviewSteps__step-content {
      margin-left: 14px;
      .OverviewSteps__step-description {
        line-height: 18px;
        margin-bottom: 10px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        &.OverviewSteps__step-description--greyed {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: left;
          color: #858585;
        }
      }
      .OverviewSteps__stepTime,
      .OverviewSteps__pendingApproval,
      .OverviewSteps__rejected {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        border: 1px solid #757575;
        width: 52px;
        height: 16.6px;
        box-sizing: content-box;
        border-radius: 4px;
        .OverviewSteps__clockIcon {
          margin-right: 4.3px;
        }
        span {
          font-size: 10px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 11px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
      .OverviewSteps__pendingApproval,
      .OverviewSteps__rejected {
        background-color: #fcb35c;
        width: 88px;
        border: none;
        span {
          color: white;
        }
      }
      .OverviewSteps__rejected {
        background-color: #f45c2d;
        width: 52px;
      }
    }
  }
}
