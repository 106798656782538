@import '../../mixins.scss';

.PayoutPaymaxTerms {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  &__title {
    @include payoutTitle;
  }
  &__body {
    margin-top: 10px;
    .PayoutPaymaxTerms__subText {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      width: calc(100% - 92px);
      margin-left: 46px;
      color: #233b58;
      margin-bottom: 21px;
    }
    .PayoutPaymaxTerms__termsList {
      list-style-type: none;
      width: calc(100% - 110px);
      margin-left: 6px;
      .PayoutPaymaxTerms__termsListItem {
        margin-bottom: 20px;
        .PayoutPaymaxTerms__termsListContent {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
        p {
          color: #233b58;
          margin: -4px 0 0 8px;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  &__footerContainer {
    width: calc(100% - 32px);
    margin: auto auto 0;
  }
}
