@import '../../../mixins.scss';

.AttendancePolicy {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  padding: 0 15px;
  &__title {
    margin-top: 11px;
    margin-bottom: 21px;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 22.2px;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: 25px;
  }
  &__spacer {
    flex: 1;
  }
  &__button {
    @include primaryButton;
    margin: auto auto 18px;
    width: calc(100% - 32px);
    display: block;
  }
}
