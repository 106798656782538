.JobOverview__header {
  height: 50px;
  padding-top: 8px;
  width: calc(100% - 16px);
  box-sizing: content-box;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #f2f4f6;
  .JobOverview__headerTitle {
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #222b45;
    margin-left: 17px;
  }
  .JobOverview__backButton {
    margin-left: 11px;
  }
}
