@import '../../mixins.scss';

.PayoutPaymaxTerms {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  &__title {
    @include payoutTitle;
  }
  &__body {
    margin-top: 10px;
    .PayoutPaymaxTerms__subText {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      width: calc(100% - 92px);
      margin-left: 46px;
      color: #233b58;
      margin-bottom: 21px;
    }
  }
  &__footerContainer {
    width: calc(100% - 32px);
    margin: auto auto 0;
  }
}
