@mixin primaryButton {
  background-color: #7767e4;
  color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 44px;
  padding: 14px;
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  transition: 0.25s;
  &:disabled {
    opacity: 0.5;
    transition: 0.25s;
  }
}

@mixin secondaryButton {
  background-color: #fff;
  color: #7767e4;
  border-radius: 8px;
  width: 100%;
  height: 44px;
  padding: 14px;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #7767e4;
  text-decoration: none;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  transition: 0.25s;
  &:disabled {
    opacity: 0.5;
    transition: 0.25s;
  }
}

@mixin payoutTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29.3px;
  text-align: center;
  margin: 44px auto 0;
  width: 300px;
  color: #222b45;
}

@mixin errorMessage {
  color: red;
  margin-top: 10px;
  font-size: 12px;
}

@mixin customChecbox {
  /* styles for custom checkbox */
  & {
    align-items: center;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  & input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    right: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: #7767e4;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@mixin modal {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  border-radius: 20px;
  width: calc(100% - 30px);
  position: absolute;
  transform: translate(-50%, -50%);
  padding-left: 26px;
  padding-right: 26px;
  background-color: #fff;
  @media screen and (min-width: 600px) {
    & {
      width: 350px;
    }
  }
}
