.SideBar {
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid gray;
  background: #eee;
  padding: 10px;

  &__groupName {
    font-weight: bold;
  }
  &__groupItems {
    margin-left: 10px;
  }
  &__row {
    margin: 5px 0;
  }
}
