@import '../../../mixins.scss';

.Overview {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: #fbfbfb;
  .Overview__close {
    margin-top: 12px;
    border-bottom: 1px solid #f2f4f6;
    width: calc(100% - 16px);
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 8px;
    box-sizing: content-box;
    img {
      margin-left: 11px;
    }
  }
  .Overview__desc {
    margin-top: 10px;
    margin-bottom: 24px;
    width: percentage($number: 308/375);
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.68;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: 28px;
  }
  .Overview__continueButton {
    @include primaryButton;
    margin: auto auto 18px;
    width: calc(100% - 32px);
  }
}
