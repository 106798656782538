.Input {
  width: 150px;
  &__input {
    width: 100%;
  }
  &__autocomplete {
    position: absolute;
    border: 1px solid gray;
    background: white;
    z-index: 100;
  }
  &__autocompleteItem {
    padding: 5px;
    border-bottom: 1px solid gray;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: silver;
    }
  }
  &__autocomplete {
    position: absolute;
    border: 1px solid gray;
    background: white;
    z-index: 100;
  }
  &__autocompleteItem {
    padding: 5px;
    border-bottom: 1px solid gray;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: silver;
    }
  }
}
