@import '../../mixins.scss';

.JobFullfilled {
  .JobFullfilled__title {
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    width: 271px;
    margin: 10vh auto 26px;
  }
  .JobFullfilled__jobDetails {
    border: 2px solid #ff384e;
    padding: 23px 22px 0;
    border-radius: 15px;
    width: 90%;
    margin: 0 auto;
    .JobFullfilled__jobTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        p {
          img {
            margin-right: 5px;
          }
        }
      }
      span {
        background-color: #ff384e;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        border-radius: 7px;
        color: #fff;
        height: 14px;
        width: 59px;
        text-align: center;
      }
    }

    .JobFullfilled__jobDetailsData {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      border-top: 1px solid #e5e9fb;
      & > div {
        text-align: center;
      }
    }
  }
  .JobFullfilled_tipsTitle {
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin-top: 9vh;
    text-align: center;
  }
  .JobFullfilled__list {
    padding-left: 0;
    width: calc(100% - 116px);
    margin: 18px auto 9vh;
    li {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      span {
        color: #7565e0;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 2px solid #7565e0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        margin-right: 9px;
        line-height: 23px;
      }
      p {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        width: calc(100% - 26px);
        margin-top: 0;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .JobFullfilled_hint {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #8f9bb3;
    margin-bottom: 18px;
  }
  .JobFullfilled__closeButton {
    @include primaryButton;
    margin: auto auto 18px;
    width: calc(100% - 32px);
    display: block;
  }
}
