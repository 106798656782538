@import '../../mixins.scss';

.Policy {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Policy__image {
    height: 236px;
    width: 100%;
    background-color: #e5e9fb;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Policy__title {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-top: 26px;
    margin-left: 29px;
    text-align: left;
  }
  .Policy__description {
    margin-top: 15px;
    margin-left: 29px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #414141;
    width: calc(100% - 44px);
  }
  .Policy__steps {
    margin: auto auto 18px auto;
  }
  .Policy__nextButton {
    @include primaryButton;
    margin: 0 auto 18px;
    width: calc(100% - 32px);
    display: block;
  }
}
