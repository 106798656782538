html,
body,
#root {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

.row {
  display: flex;
  flex: 1;
}

.center {
  justify-content: center;
}
.right {
  justify-content: flex-end;
}
.spaceBetween {
  justify-content: space-between;
}
.spaceAround {
  justify-content: space-around;
}
.spaceEvenly {
  justify-content: space-evenly;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flex1 {
  flex: 1;
}

button {
  outline: none;
  border: none;
}

/* customized scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 2.2px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f2f4f6;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #7767e4;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #7767e4;
}
