.UploadDocument {
  &__title {
    font-size: 25px;
    color: #222b45;
    text-align: center;
    font-weight: bold;
    margin-top: 70px;
    margin-bottom: 21px;
  }
  &__uploadContainer {
    width: calc(100% - 32px);
    max-width: 400px;
    margin: 0 auto;
  }
  &__hint {
    border-radius: 5px;
    border: 1px solid #f2f4f6;
    box-shadow: 0 2px 15px 0 rgba(233, 235, 240, 0.4);
    padding: 18px 16px 13px;
    position: relative;

    h6 {
      font-size: 15px;
      color: #222b45;
      font-weight: 300;
      margin: 0;
    }
    p {
      font-size: 12px;
      color: #8f9bb3;
      margin: 6px 0 0;
    }
  }
  &__label {
    font-size: 17px;
    color: #8f9bb3;
    margin-bottom: 6px;
    font-weight: 300;
  }
  &__upload {
    border-radius: 5px;
    border: 1px solid #f2f4f6;
    box-shadow: 0 4px 20px 0 rgba(212, 223, 245, 0.345);
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  &__uploadText {
    color: #7767e4;
    font-size: 10px;
    text-decoration: none;
    margin-top: 16px;
  }
  &__uploadInput {
    display: none;
  }
  &__infoIcon {
    position: absolute;
    top: 14px;
    right: 14px;
  }
}
