@import '../../mixins.scss';

.PayoutTerms {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  .PayoutTerms__title {
    @include payoutTitle;
  }
  .PayoutTerms__bodyContainer {
    width: calc(100% - 32px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 40px;
    .PayoutTerms__termsTextContainer {
      height: 260px;
      width: 258px;
      margin: 0 auto;
      overflow-y: auto;
      padding-right: 10px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #233b58;
      }
    }
    .PayoutTerms__errorMessage {
      @include errorMessage;
      margin: 0;
    }
  }
}
