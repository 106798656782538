@import '../../../mixins.scss';

.Shifts {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Shifts__close {
    margin-top: 12px;
    border-bottom: 1px solid #f2f4f6;
    width: calc(100% - 16px);
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 8px;
    box-sizing: content-box;
    img {
      margin-left: 20px;
    }
  }
  .Shifts__desc {
    margin-top: 10px;
    margin-bottom: 32px;
    width: percentage($number: 308/375);
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.68;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: 28px;
  }
  .Shifts__tableContainer {
    height: 79vh;
    overflow-y: auto;
  }
  .Shifts__table {
    width: calc(100% - 28px - 22px);
    margin: 0 auto;
    border-collapse: collapse;
    .Shifts__tableHeader {
      border-bottom: 1px solid #e5e9fb;
    }
    .Shifts__tableHeaderCell {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      margin-bottom: 6px;
      img {
        width: 16.5px;
        margin-bottom: 3px;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #838184;
      }
    }
    tr {
      td {
        width: 33.3%;
        padding-top: 9px;
        &.Shifts__date {
          div {
            padding: 3px 9px 5px;
            font-size: 14px;
            margin-left: 8px;
            margin-right: auto;
            width: max-content;
            border-radius: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2em;
            letter-spacing: normal;
            text-align: center;
            color: #222b45;
            background-color: rgba(#7767e4, 0.1);
          }
        }
        &.Shifts__time {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2em;
          letter-spacing: normal;
          text-align: center;
          span:first-child {
            color: #414141;
          }
          span:last-child {
            color: #838184;
          }
        }
        &.Shifts__hours {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2em;
          letter-spacing: normal;
          color: #838184;
          span {
            display: block;
            margin-left: auto;
            margin-right: 38px;
            width: max-content;
          }
        }
      }
    }
    .Shifts__tableHeaderCell--rightAlign {
      margin-left: auto;
      margin-right: 23px;
    }
    .Shifts__tableHeaderCell--centerAlign {
      margin: 0 auto 6px;
    }
    .Shifts__tableHeaderCell--leftAlign {
      margin-right: auto;
      margin-left: 23px;
    }
  }
  .Shifts_nextButton {
    @include primaryButton;
    margin: auto auto 18px;
    width: calc(100% - 32px);
    display: block;
  }
}
