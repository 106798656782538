.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &__body {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    max-height: 80vh;
    overflow: auto;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 80%;
    background: white;
    // border: 1px solid gray;
    border-radius: 5px;
    padding: 20px;
  }
  &__background {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
