@import '../../mixins.scss';

.JobOverview {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  .JobOverview__videoPlayer {
    position: relative;
    width: 100%;
    height: 234px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    margin-bottom: 20px;
    span {
      color: #fff;
    }
    &.ended::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
      background-color: black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 64px 64px;
      background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
    }
  }
  .JobOverview__overviewDescription {
    height: 41.5vh;
    margin-left: 22px;
    margin-right: 8px;
    overflow-y: auto;
  }
  .JobOverview__title {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  .JobOverview__description {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #414141;
    padding-right: 13px;
  }
  .JobOverview__descriptionTitle {
    font-weight: 500;
    line-height: 65px;
  }
  .JobOverview__startQuizButton {
    @include primaryButton;
    margin: auto auto 18px;
    width: calc(100% - 32px);
  }
}
