@import '../../mixins.scss';

.PayoutIban {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  .PayoutIban__title {
    @include payoutTitle;
  }
  .PayoutIban__formBody {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 81px auto 0;
    width: calc(100% - 32px);
  }
  .footer-container {
    margin-top: auto;
  }
  .hide {
    visibility: hidden;
  }
}
