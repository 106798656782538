@import '../../mixins.scss';

.UploadRequiredDocuments {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  &__errorMessage {
    @include errorMessage;
    margin-left: 16px;
  }
  &__footer {
    margin: auto auto 14px;
    width: calc(100% - 32px);
  }
  &__stepper {
    margin-bottom: 11px;
  }
  &__infoMessage {
    color: #858585;
    font-size: 10px;
    text-align: center;
  }
  &__submitButton {
    @include primaryButton;
    display: block;
  }
}
