.Stepper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  .Stepper__step {
    width: 23px;
    height: 2px;
    margin: 0 0 0 2px;
    border-radius: 1px;
    background-color: #f2f4f6;
    &.active {
      background-color: #6249e3;
    }
  }
}
