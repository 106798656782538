.PolicyItem {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  &__checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #7767e4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: #7767e4;
    }
    &__img {
      margin: auto;
    }
  }
  &__label {
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}
