@import '../../mixins.scss';

.PayoutName {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fbfbfb;
  &__title {
    @include payoutTitle;
  }
  &__formBody {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 52px auto 0;
    width: calc(100% - 32px);
  }
  &__footerContainer {
    margin-top: auto;
  }
}
