@import '../../mixins.scss';

.Payout__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  .Payout__stepperContainer {
    display: flex;
    justify-content: center;
  }
  .Payout__hint {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 8px;
    color: #858585;
  }
  .Payout__nextButton {
    @include primaryButton;
    margin: auto auto 18px;
    display: block;
    width: 100%;
    margin-top: 15px;
  }
}
